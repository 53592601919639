// React
import React, { useEffect, useState } from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';

// Utilities
import { localize, localizeLink, disableScroll } from 'utilities';
import { useResponsive, useAccount } from 'hooks';
import { TransitionLink } from 'transition';

// Components

const MobileNavigationComponent = ({ pageContext, content, location }) => {
    const { locale, labels } = pageContext;
    const { mainNavigation, secondaryNavigation } = content;

    // Hook: Account
    const { account, logout } = useAccount();

    // Theme
    const [theme] = useGlobal('headerTheme');

    // MobileNavigationActive
    const [mobileNavigationActive] = useGlobal('mobileNavigationActive');

    // Disable scroll when active
    useEffect(() => {
        if (mobileNavigationActive) {
            disableScroll.on();
        } else {
            disableScroll.off();
        }
    }, [mobileNavigationActive]);

    // Breakpoint
    const breakpoint = useResponsive();

    // Submenu
    const [submenuKey, setSubmenuKey] = useState('shop');

    return breakpoint < 30 ? (
        <>
            <MobileNavigation {...{ theme, active: mobileNavigationActive }}>
                <MobileNavigationContent>
                    <NavigationSection {...{ active: mobileNavigationActive }}>
                        {mainNavigation.map(item => {
                            // Get page
                            const page = item.page[0];

                            // Do we have a sub menu
                            const hasSubmenu = page.submenuKey;

                            // Do we have an active sub menu
                            const active = page.submenuKey === submenuKey;
                            return (
                                <NavigationItem
                                    {...{
                                        active,
                                    }}
                                    key={item.name}>
                                    {!active && hasSubmenu ? (
                                        <span
                                            role="button"
                                            tabIndex="-1"
                                            onKeyDown={() =>
                                                setSubmenuKey(page.submenuKey)
                                            }
                                            onClick={() =>
                                                setSubmenuKey(page.submenuKey)
                                            }>
                                            {item.name}
                                        </span>
                                    ) : (
                                        <TransitionLink
                                            from={location}
                                            to={localizeLink(
                                                `/${page.slug}/`,
                                                locale
                                            )}
                                            transition={[
                                                'default',
                                                {
                                                    theme: page.theme
                                                        ? s.themeColor(
                                                              page.theme
                                                          )
                                                        : s.color('white'),
                                                },
                                            ]}>
                                            {item.name}
                                        </TransitionLink>
                                    )}
                                    {page.submenuKey && (
                                        <SubNavigationSection
                                            {...{
                                                active,
                                                pointerEvents:
                                                    mobileNavigationActive
                                                        ? 1
                                                        : 0,
                                            }}>
                                            {/* CATEGORIES */}
                                            {page.categories &&
                                                page.categories.map(
                                                    category =>
                                                        category.active && (
                                                            <SubNavigationItem
                                                                key={
                                                                    category.name
                                                                }>
                                                                <TransitionLink
                                                                    from={
                                                                        location
                                                                    }
                                                                    to={localizeLink(
                                                                        `/${page.slug}/`,
                                                                        locale
                                                                    )}
                                                                    transition={[
                                                                        'default',
                                                                        {
                                                                            theme: page.theme
                                                                                ? s.themeColor(
                                                                                      page.theme
                                                                                  )
                                                                                : s.color(
                                                                                      'white'
                                                                                  ),
                                                                        },
                                                                    ]}
                                                                    state={{
                                                                        category:
                                                                            category.category,
                                                                    }}>
                                                                    {
                                                                        category.name
                                                                    }
                                                                </TransitionLink>
                                                            </SubNavigationItem>
                                                        )
                                                )}

                                            {/* LINKS */}
                                            {page.sections &&
                                                page.sections.map(link => {
                                                    const subpage =
                                                        link.pageLink[0];

                                                    return (
                                                        <SubNavigationItem
                                                            key={link.name}>
                                                            <TransitionLink
                                                                from={location}
                                                                to={localizeLink(
                                                                    `/${subpage.slug}/`,
                                                                    locale
                                                                )}
                                                                transition={[
                                                                    'default',
                                                                    {
                                                                        theme: subpage.theme
                                                                            ? s.themeColor(
                                                                                  subpage.theme
                                                                              )
                                                                            : s.color(
                                                                                  'white'
                                                                              ),
                                                                    },
                                                                ]}>
                                                                {link.name}
                                                            </TransitionLink>
                                                        </SubNavigationItem>
                                                    );
                                                })}
                                        </SubNavigationSection>
                                    )}
                                </NavigationItem>
                            );
                        })}
                    </NavigationSection>
                    <Footer {...{ active: mobileNavigationActive }}>
                        {secondaryNavigation.map(item => {
                            const page = item.page[0];
                            return (
                                <FooterNavigationItem key={item.name}>
                                    <TransitionLink
                                        to={localizeLink(
                                            `/${page.slug}/`,
                                            locale
                                        )}>
                                        {item.name}
                                    </TransitionLink>
                                </FooterNavigationItem>
                            );
                        })}
                        <FooterNavigationItem>
                            <TransitionLink
                                to={localizeLink(`/account/`, locale)}>
                                {labels.PARTIALS.HEADER.ACCOUNT}
                            </TransitionLink>
                        </FooterNavigationItem>
                        <FooterNavigationItem>
                            <TransitionLink to={localizeLink(`/cart/`, locale)}>
                                {labels.PARTIALS.HEADER.CART}
                            </TransitionLink>
                        </FooterNavigationItem>
                        {account && (
                            <FooterNavigationItem>
                                <TransitionLink
                                    to={localizeLink(`/`, locale)}
                                    onClick={() => logout()}>
                                    {labels.PARTIALS.HEADER.LOGOUT}
                                </TransitionLink>
                            </FooterNavigationItem>
                        )}
                    </Footer>
                </MobileNavigationContent>
            </MobileNavigation>
        </>
    ) : null;
};

const MobileNavigation = styled.div`
    position: fixed;
    background: ${props => props.theme.dark()};
    color: ${props => props.theme.text()};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${s.layout.zIndex.mobileNavigation};
    pointer-events: ${props => (props.active ? 'auto' : 'none')};
    opacity: ${props => (props.active ? 1 : 0)};
    transition-property: opacity;
    transition-duration: 400ms;
    display: flex;
    justify-content: center;
`;

const MobileNavigationContent = styled.div`
    ${s.grid.contentWidth};

    ${s.grid.gutter(['margin-right', 'margin-left'], {
        0: -1,
    })};

    ${s.grid.gutter(['padding-right', 'padding-left'], {
        0: 1,
    })};

    position: relative;
    margin-top: 90px;
    margin-bottom: 30px;
`;

const NavigationSection = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    transition: ${props =>
        props.active
            ? `transform 600ms 400ms ${s.easings.smooth.out}, opacity 500ms 400ms`
            : `transform 400ms ${s.easings.smooth.in}, opacity 300ms`};
    transform: ${props =>
        props.active ? 'translateY(0)' : 'translateY(10px)'};
    opacity: ${props => (props.active ? 1 : 0)};
`;

const NavigationItem = styled.div`
    white-space: nowrap;
    opacity: ${props => (props.active ? 1 : 0.6)};
    transition: opacity 400ms;
    cursor: pointer;

    ${s.responsive.property('margin-bottom', {
        0: '2em',
    })};

    ${s.fonts.style('h11')};

    ${s.grid.columns('width', {
        0: 1.8,
    })};
`;

const SubNavigationSection = styled.div`
    ${s.grid.columns('left', {
        0: 1.8,
    })};

    ${s.fonts.style('h3Responsive')};

    position: absolute;
    top: -0.3em;

    display: flex;
    flex-direction: column;

    transition: ${props =>
        props.active
            ? `transform 600ms ${s.easings.smooth.out}, opacity 500ms`
            : `transform 400ms ${s.easings.smooth.in}, opacity 300ms`};

    transform: ${props =>
        props.active ? 'translateY(0)' : 'translateY(10px)'};

    opacity: ${props => (props.active ? 1 : 0)};

    pointer-events: ${props =>
        props.pointerEvents && props.active ? 'auto' : 'none'};
`;

const SubNavigationItem = styled.div`
    white-space: nowrap;

    ${s.responsive.property('margin-bottom', {
        0: '0.9em',
    })};
`;

const Footer = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;

    ${s.responsive.property('justify-content', {
        0: 'space-between',
        10: 'flex-start',
    })};

    transition: ${props =>
        props.active
            ? `transform 600ms 800ms ${s.easings.smooth.out}, opacity 500ms 800ms`
            : `transform 400ms ${s.easings.smooth.in}, opacity 300ms`};
    transform: ${props =>
        props.active ? 'translateY(0)' : 'translateY(10px)'};
    opacity: ${props => (props.active ? 1 : 0)};
`;

const FooterNavigationItem = styled.div`
    white-space: nowrap;
    ${s.fonts.style('h11')};
    & + & {
        ${s.responsive.property('margin-left', {
            0: 25,
        })};
    }
`;

export default props => {
    const { locale } = props.pageContext;
    const { allDatoCmsPartialHeader } = useStaticQuery(graphql`
        query {
            allDatoCmsPartialHeader {
                edges {
                    node {
                        ...DatoCmsPartialHeader
                    }
                }
            }
        }
    `);

    return (
        <MobileNavigationComponent
            {...{
                content: localize(allDatoCmsPartialHeader, locale),
                ...props,
            }}
        />
    );
};
